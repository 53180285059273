import { Link } from "gatsby";
import * as React from "react";

const Disclaimer = ({ colorScheme }) => {
  return (
    <div className="mt-4 sm:mt-10 p-6 sm:p-10 bg-black/10">
      <div className="flex justify-between">
        <div>
          <h3>Issues? Questions?</h3>
          Contact Mother G Resa on Telegram. <br />
          <a href="https://telegram.me/mothergresa">@mothergresa</a>
        </div>
        <img className="-mb-4 sm:-mb-10" src="https://i.ibb.co/5Tf22zr/a.png" alt="A dinosaur" />
      </div>
    </div>
  )
};

export default Disclaimer;
